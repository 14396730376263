import React, { useState } from 'react';
import { MenuItem, Button, Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const MachineIdentitiesForm = ({ teams, addIdentity, selectedTeam }) => {
    const [team, setTeam] = useState('');
    const [arn, setArn] = useState('');

    const handleArnChange = (event) => {
        setArn(event.target.value);
    };

    const handleTeamChange = (event) => {
        setTeam(event.target.value);
    };

    const handleClick = () => {
        if (arn && team) {
            addIdentity(arn, team);
            setArn('');
        }
    };

    const conditionalRender = () => {
        if (selectedTeam === 'My Teams') {
            return teams.map((team) => (
                <MenuItem key={team} value={team}>
                    {team}
                </MenuItem>
            ));
        } else {
            return (
                <MenuItem key={selectedTeam} value={selectedTeam}>
                    {selectedTeam}
                </MenuItem>
            );
        }
    };

    return (
        <Grid
            container
            spacing={2}
            style={{
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid item xs={7}>
                <TextField
                    id="arn"
                    type="text"
                    label="ARN"
                    margin="normal"
                    variant="outlined"
                    style={{ width: '100%' }}
                    value={arn}
                    onChange={handleArnChange}
                />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    id="team"
                    select
                    label="Team"
                    margin="normal"
                    variant="outlined"
                    style={{ width: '100%' }}
                    value={team}
                    onChange={handleTeamChange}
                >
                    {conditionalRender()}
                </TextField>
            </Grid>

            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    aria-label="add"
                    onClick={handleClick}
                    startIcon={<AddIcon />}
                >
                    Add MI
                </Button>
            </Grid>
        </Grid>
    );
};

export default MachineIdentitiesForm;
