import React, { useState, useEffect, useCallback } from 'react';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import {
    AppBar,
    Box,
    CircularProgress,
    CssBaseline,
    Drawer,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Divider,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import Config from './config';
import AccessTokens from './components/access_tokens/AccessTokens';
import MachineIdentities from './components/machine_identities/MachineIdentities';
import WorkforceComponent from './components/workforce_components/WorkforceComponent';
import { withRouter, Route, Redirect, Switch, Link } from 'react-router-dom';
import LogoutPage from './components/logout/LogoutPage';
import useFetch from './util/useFetch';
import { makeStyles } from '@material-ui/core/styles';
import NestedList from './components/nested_list/NestedList';
import NestedListItem from './components/nested_list/NestedListItem';
import memoize from 'memoizee';
import SandboxTicketComponent from './components/sandbox-tickets/SandboxTicketComponent';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    navbar: {
        flexGrow: 1,
    },
    tabs: {
        flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
    },
}));

const App = (props) => {
    const [accessToken, setAccessToken] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
    const [auth, setAuth] = useState(null);
    const [teamsHierarchy, setTeamsHierarchy] = useState([]);
    const [myTeams, setMyTeams] = useState([
        { label: 'My Teams', query: '', children: [], teamId: '' },
    ]);
    const [selectedTeam, setSelectedTeam] = useState(myTeams[0]);

    const [doFetch, isLoading] = useFetch(accessToken);

    const openProfileMenu = (event) => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const closeProfileMenu = () => {
        setProfileMenuAnchorEl(null);
    };

    const logout = () => {
        if (auth) {
            auth.signOut();
        }
    };

    const redirectToDocs = () => {
        window.open(Config.docs.swagger_url);
    };

    useEffect(() => {
        /**
         * Get a list of the current team's name plus all descendant teams' names
         */
        const getTeamNames = (team) => [
            team.u_name,
            ...team.children.map(getTeamNames),
        ];

        /**
         * traverses the tree of teams to build a new tree with the required format
         */
        const traverse = (team) => ({
            label: team.u_name,
            query: `?all=true&team_id=${
                team.u_number
            }&team=${encodeURIComponent(getTeamNames(team).join(','))}`,
            teamId: team.u_number,
            children: team.children.map(traverse),
            teamNames: encodeURIComponent(getTeamNames(team).join(',')),
        });

        /**
         * finds a team by name from a list of teams trees
         * @param teams - a list of teams trees
         * @param team_name - the name of the team to find
         */
        const findTeam = (teams, team_name) => {
            const findTeamInner = (team) => {
                if (team.label === team_name) {
                    return team;
                }
                return findTeam(team.children, team_name);
            };

            return teams.map(findTeamInner).find((team) => !!team);
        };

        /**
         * fetches and formats the tree of teams
         */
        const getTeamsHierarchy = async () => {
            const teamsHierarchyPromise = doFetch(
                `teams-hierarchy`,
                'GET',
                null,
                null
            );
            const myTeamsPromise = doFetch(`teams`, 'GET', null, null);

            const teamsHierarchyJson = await teamsHierarchyPromise;
            if (teamsHierarchyJson.result) {
                const teams = teamsHierarchyJson.result.map(traverse);
                setTeamsHierarchy(teams);

                const myTeamsJson = await myTeamsPromise;
                if (myTeamsJson.result) {
                    const newMyTeams = [
                        {
                            label: 'My Teams',
                            query: '',
                            teamId: '',
                            children: myTeamsJson.result
                                .map((team_name) => findTeam(teams, team_name))
                                .filter((team) => !!team),
                        },
                    ];
                    setMyTeams(newMyTeams);
                    setSelectedTeam(newMyTeams[0]);
                }
            }
        };

        getTeamsHierarchy();
    }, [doFetch]);

    const setCurrentTeam = useCallback(
        (team) => {
            if (team) {
                setSelectedTeam(team);
            }
        },
        [setSelectedTeam]
    );

    const getCurrentTeamUpdater = useCallback(
        memoize((team) => () => setCurrentTeam(team)),
        [setCurrentTeam]
    );

    const buildNestedList = useCallback(
        (teams) => {
            if (teams && teams.length) {
                return teams.map((team) => (
                    <NestedListItem
                        text={team.label}
                        onClick={getCurrentTeamUpdater(team)}
                        selected={team === selectedTeam}
                    >
                        {buildNestedList(team.children)}
                    </NestedListItem>
                ));
            }
            return null;
        },
        [selectedTeam, getCurrentTeamUpdater]
    );

    const classes = useStyles();

    return (
        <>
            <CssBaseline />

            <div className={classes.root}>
                {/* Navigation Bar*/}
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" style={{ flexGrow: 1 }}>
                            Change API - Access Management
                        </Typography>

                        <IconButton
                            aria-label="email of current user"
                            color="inherit"
                            onClick={openProfileMenu}
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="profile-menu"
                            anchorEl={profileMenuAnchorEl}
                            keepMounted
                            open={Boolean(profileMenuAnchorEl)}
                            onClose={closeProfileMenu}
                        >
                            <MenuItem onClick={redirectToDocs}>
                                API Docs
                            </MenuItem>
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>

                        <Typography variant="body2">{currentUser}</Typography>
                    </Toolbar>
                </AppBar>

                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{ paper: classes.drawerPaper }}
                    anchor="left"
                >
                    <div className={classes.toolbar}></div>
                    <Divider />
                    <NestedList>{buildNestedList(myTeams)}</NestedList>
                    <Divider />
                    <NestedList>{buildNestedList(teamsHierarchy)}</NestedList>
                </Drawer>

                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Switch>
                        <Route path={Config.routes.logout} exact>
                            <LogoutPage />
                        </Route>
                        <Route path="/">
                            <Login
                                setAccessToken={setAccessToken}
                                setAuth={setAuth}
                                setCurrentUser={setCurrentUser}
                            />
                            {/* Sub-Navigation Bar (Tabs)*/}
                            <AppBar
                                position="static"
                                color={'default'}
                                className={classes.navbar}
                            >
                                <Toolbar>
                                    <Tabs
                                        value={props.history.location.pathname}
                                        className={classes.tabs}
                                    >
                                        <Tab
                                            label="Workload Catalog"
                                            component={Link}
                                            to={Config.routes.workloadCatalog}
                                        />
                                        <Tab
                                            label="Machine Identities"
                                            component={Link}
                                            to={Config.routes.machineIdentities}
                                        />
                                        <Tab
                                            label="Access Tokens"
                                            component={Link}
                                            to={Config.routes.accessTokens}
                                        />
                                        <Tab
                                            label="Sandbox Change Tickets"
                                            component={Link}
                                            to={Config.routes.sandboxTicket}
                                        />
                                    </Tabs>
                                </Toolbar>
                            </AppBar>
                            <br />
                            {isLoading ? <Loading /> : null}
                            {accessToken ? (
                                <Switch>
                                    <Route path={Config.routes.workloadCatalog}>
                                        <TabPanel>
                                            <WorkforceComponent
                                                doFetch={doFetch}
                                                selectedTeam={selectedTeam}
                                            />
                                        </TabPanel>
                                    </Route>
                                    <Route
                                        path={Config.routes.machineIdentities}
                                    >
                                        <TabPanel>
                                            <MachineIdentities
                                                doFetch={doFetch}
                                                selectedTeam={selectedTeam}
                                            />
                                        </TabPanel>
                                    </Route>
                                    <Route path={Config.routes.accessTokens}>
                                        <TabPanel>
                                            <AccessTokens
                                                doFetch={doFetch}
                                                selectedTeam={selectedTeam}
                                            />
                                        </TabPanel>
                                    </Route>
                                    <Route path={Config.routes.sandboxTicket}>
                                        <TabPanel>
                                            <SandboxTicketComponent
                                                doFetch={doFetch}
                                                selectedTeam={selectedTeam}
                                            />
                                        </TabPanel>
                                    </Route>
                                    <Redirect
                                        to={Config.routes.workloadCatalog}
                                    />
                                </Switch>
                            ) : (
                                <h2>Logging in with Okta...</h2>
                            )}
                        </Route>
                    </Switch>
                </main>
            </div>
        </>
    );
};

const Loading = () => (
    <CircularProgress
        size={300}
        style={{
            position: 'absolute',
            zIndex: 999999,
            left: '40%',
            top: '20%',
        }}
    />
);

const TabPanel = (props) => {
    const { children } = props;

    return (
        <Typography component="div" role="tabpanel">
            <Box p={2}>{children}</Box>
        </Typography>
    );
};

/**
 * This component performs login and then renders nothing
 */
const Login = ({ setAccessToken, setAuth, setCurrentUser }) => {
    React.useEffect(() => {
        // Initialize with authentication
        const authData = {
            AppWebDomain: Config.COGNITO.APP_WEB_DOMAIN,
            ClientId: Config.COGNITO.CLIENT_ID,
            RedirectUriSignIn: Config.COGNITO.REDIRECT_URI,
            RedirectUriSignOut: Config.COGNITO.LOGOUT_URI,
            TokenScopesArray: ['openid', 'profile'],
            UserPoolId: Config.COGNITO.USER_POOL_ID,
        };
        const auth = new CognitoAuth(authData);
        auth.userhandler = {
            onSuccess: (result) => {
                setAccessToken(result.accessToken.jwtToken);
            },
            onFailure: (err) => {
                console.log(err);
            },
        };
        // AM - Note: Tried adding this in the userHandler section, but it intermittently fails.
        setCurrentUser(auth.signInUserSession.idToken.payload.email);
        setAuth(auth);
        auth.parseCognitoWebResponse(window.location.href);
        auth.getSession();
    }, [setAccessToken, setAuth, setCurrentUser]);

    return null;
};

export default withRouter(App);
