import React, { useState, useEffect, useCallback } from 'react';
import { Container, Grid } from '@material-ui/core';

import MachineIdentitiesForm from './MachineIdentitiesForm';
import MachineIdentitiesTable from './MachineIdentitiesTable';

const MachineIdentities = React.memo(({ doFetch, selectedTeam }) => {
    const [teams, setTeams] = useState([]);
    const [identities, setIdentities] = useState([]);

    useEffect(() => {
        const getTeams = async () => {
            const json = await doFetch('teams', 'GET', null, null);
            setTeams(json.result);
        };

        getTeams();
    }, [doFetch]);

    const getIdentities = useCallback(async () => {
        const json = await doFetch(
            `identity${selectedTeam.query}`,
            'GET',
            null,
            null
        );
        setIdentities(json.result);
    }, [doFetch, setIdentities, selectedTeam.query]);

    useEffect(() => {
        getIdentities();
    }, [getIdentities]);

    const addIdentity = async (arn, team) => {
        const headers = { 'Content-Type': 'application/json' };
        let body;
        if (selectedTeam.teamId) {
            body = {
                arn: arn,
                team: team,
                team_id: selectedTeam.teamId,
            };
        } else {
            body = {
                arn: arn,
                team: team,
            };
        }
        const json = await doFetch(
            'identity',
            'POST',
            headers,
            JSON.stringify(body)
        );
        if ('status' in json && json.status === 'success') {
            getIdentities();
        }
    };

    const deleteIdentity = async (arn) => {
        const endpoint = `identity/${encodeURIComponent(arn)}`;

        const json = await doFetch(endpoint, 'DELETE', null, null);
        if ('status' in json && json.status === 'success') {
            getIdentities();
        }
    };

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MachineIdentitiesForm
                        teams={teams}
                        addIdentity={addIdentity}
                        selectedTeam={selectedTeam.label}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MachineIdentitiesTable
                        myTeams={teams}
                        data={identities}
                        deleteIdentity={deleteIdentity}
                        selectedTeam={selectedTeam.label}
                    />
                </Grid>
            </Grid>
        </Container>
    );
});

export default MachineIdentities;
