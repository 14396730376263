import React from 'react';
import Config from '../../config';
import {
    Button,
    Card,
    CardContent,
    CardActions,
    Grid,
    Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const LogoutPage = () => {
    const history = useHistory();

    const login = () => {
        history.push(Config.routes.index);
    };

    return (
        <Box m={2}>
            <Grid container justify="center">
                <Card>
                    <CardContent>You are successfully logged out</CardContent>
                    <CardActions>
                        <Button size="small" onClick={login}>
                            Login
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Box>
    );
};

export default LogoutPage;
