import React, { useEffect, useState, useCallback } from 'react';
import {
    Container,
    Grid,
    Paper,
    TextField,
    Toolbar,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Box,
    IconButton,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import AccessTokensForm from './AccessTokensForm';

const AccessTokens = ({ doFetch, selectedTeam }) => {
    const [teams, setTeams] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [accessToken, setAccessToken] = useState(
        'Click Create Token to generate a token'
    );

    useEffect(() => {
        const getTeams = async () => {
            const json = await doFetch('teams', 'GET', null, null);
            setTeams(json.result);
        };

        getTeams();
    }, [doFetch]);

    const getTokens = useCallback(async () => {
        const json = await doFetch(
            `identity/token${selectedTeam.query}`,
            'GET',
            null,
            null
        );
        setTokens(json.result);
    }, [doFetch, selectedTeam]);

    useEffect(() => {
        getTokens();
    }, [getTokens]);

    const deleteFromTokensArray = (prefix) => {
        // Create a new array excluding the token with the matching prefix
        const updatedTokens = tokens.filter((token) => token.prefix !== prefix);

        // Update the state with the new array
        setTokens(updatedTokens);
    };

    const deleteToken = async (prefix) => {
        const headers = { 'Content-Type': 'application/json' };
        const body = {
            prefix: prefix,
        };
        const json = await doFetch(
            'identity/token',
            'DELETE',
            headers,
            JSON.stringify(body)
        );

        if ('status' in json && json.status === 'success') {
            // this is called rather than `getTokens` because
            // the DynamoDB table often takes longer to update than
            // the time until the next fetch tokens call.
            deleteFromTokensArray(prefix);
        }
    };

    const createAccessToken = async (team, tokenName) => {
        const headers = { 'Content-Type': 'application/json' };
        let body;
        if (selectedTeam.teamId) {
            body = {
                team: team,
                name: tokenName,
                team_id: selectedTeam.teamId,
            };
        } else {
            body = {
                team: team,
                name: tokenName,
            };
        }

        const json = await doFetch(
            'identity/token',
            'POST',
            headers,
            JSON.stringify(body)
        );

        if ('status' in json && json.status === 'success') {
            setAccessToken(json.result.accessToken);
            getTokens();
        }
    };

    const handleClick = (prefix) => {
        const confirm = window.confirm(
            `Are you sure you want to delete token ${prefix}?`
        );

        if (confirm) {
            deleteToken(prefix);
        }
    };

    const shouldDisable =
        selectedTeam.label !== 'My Teams' &&
        !teams.includes(selectedTeam.label);

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccessTokensForm
                        teams={teams}
                        createAccessToken={createAccessToken}
                        selectedTeam={selectedTeam.label}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Paper>
                        <Container>
                            <Typography component="div">
                                <Toolbar>
                                    <Typography variant="h6">
                                        Access Token
                                    </Typography>
                                </Toolbar>
                                <TextField
                                    id="token"
                                    type="text"
                                    margin="normal"
                                    variant="filled"
                                    helperText="Tokens cannot be retrieved once generated, so keep them safe
                  and secure!"
                                    style={{
                                        width: '80%',
                                        align: 'center',
                                        margin: '2em',
                                    }}
                                    value={accessToken}
                                />
                            </Typography>
                        </Container>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Container>
                            <Typography component="div">
                                <Toolbar>
                                    <Typography variant="h6">
                                        Manage Tokens
                                    </Typography>
                                </Toolbar>
                                <Table width>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Box>Name</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>Team</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>Created By</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>Created On</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>Prefix</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>Actions</Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tokens.map((token) => {
                                            return (
                                                <TableRow key={token.prefix}>
                                                    <TableCell>
                                                        {token.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {token.team}
                                                    </TableCell>
                                                    <TableCell>
                                                        {token.created_by}
                                                    </TableCell>
                                                    <TableCell>
                                                        {token.created_on}
                                                    </TableCell>
                                                    <TableCell>
                                                        {token.prefix}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            color="primary"
                                                            component="span"
                                                            onClick={() => {
                                                                handleClick(
                                                                    token.prefix
                                                                );
                                                            }}
                                                            disabled={
                                                                shouldDisable
                                                            }
                                                        >
                                                            <DeleteOutline />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Typography>
                            <br />
                        </Container>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AccessTokens;
