import * as React from 'react';
import MaterialTable from 'material-table';
import { Container, Button } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const SandboxTicketComponent = React.memo(({ doFetch, selectedTeam }) => {
    const [sandboxTickets, setSandboxTickets] = React.useState([]);
    const [sandboxOnlyTickets, setSandboxOnlyTickets] = React.useState(false);

    const tableIcons = {
        Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: React.forwardRef((props, ref) => (
            <DeleteOutline {...props} ref={ref} />
        )),
        DetailPanel: React.forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: React.forwardRef((props, ref) => (
            <SaveAlt {...props} ref={ref} />
        )),
        Filter: React.forwardRef((props, ref) => (
            <FilterList {...props} ref={ref} />
        )),
        FirstPage: React.forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
        )),
        LastPage: React.forwardRef((props, ref) => (
            <LastPage {...props} ref={ref} />
        )),
        NextPage: React.forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        PreviousPage: React.forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: React.forwardRef((props, ref) => (
            <Clear {...props} ref={ref} />
        )),
        Search: React.forwardRef((props, ref) => (
            <Search {...props} ref={ref} />
        )),
        SortArrow: React.forwardRef((props, ref) => (
            <ArrowUpward {...props} ref={ref} />
        )),
        ThirdStateCheck: React.forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
        )),
        ViewColumn: React.forwardRef((props, ref) => (
            <ViewColumn {...props} ref={ref} />
        )),
    };

    React.useEffect(() => {
        const getSandboxTickets = async () => {
            let query = '?myTeams=true';
            if (selectedTeam.label !== 'My Teams') {
                query = `?team=${selectedTeam.teamNames}`;
            }
            const json = await doFetch(
                `change/sandbox${query}`,
                'GET',
                null,
                null
            );

            const reshapedData = json.result.map((res) => {
                // turn outage planned and traffic shunt from booleans to string so it displays
                res.outage_planned = res.outage_planned.toString();
                res.traffic_shunt = res.traffic_shunt.toString();

                return res;
            });
            setSandboxTickets(reshapedData);
            setSandboxOnlyTickets(false);
        };

        getSandboxTickets();
    }, [doFetch, selectedTeam]);

    const columns = [
        { title: 'Number', field: 'number' },
        { title: 'Primary CI', field: 'primary_ci', defaultGroupOrder: 0 },
        { title: 'Primary CI Number', field: 'primary_ci_number' },
        { title: 'Short Description', field: 'short_description' },
        { title: 'Description', field: 'description' },
        { title: 'Start Date', field: 'start_date' },
        { title: 'End Date', field: 'end_date' },
        { title: 'Environment', field: 'environment' },
        { title: 'Impact', field: 'impact' },
        { title: 'Source', field: 'source' },
        { title: 'Scope', field: 'scope' },
        { title: 'Outage Planned', field: 'outage_planned' },
        { title: 'Traffic Shunt', field: 'traffic_shunt' },
        { title: 'State', field: 'state' },
        { title: 'Risk', field: 'risk' },
        { title: 'Company', field: 'company' },
        { title: 'Category', field: 'category' },
        { title: 'Type', field: 'type' },
    ];

    const options = {
        filtering: true,
        grouping: true,
        defaultExpanded: true,
    };

    const getActionTitle = () => {
        if (sandboxOnlyTickets) {
            return 'Unfilter by Sandbox Only CI';
        } else {
            return 'Filter by Sandbox Only CI';
        }
    };

    const actions = [
        {
            icon: () => {
                return (
                    <Button variant="contained" color="primary">
                        {getActionTitle()}
                    </Button>
                );
            },
            tooltip: 'Filter by Sandbox Only CI',
            isFreeAction: true,
            onClick: async (event) => {
                let json;
                if (sandboxOnlyTickets) {
                    // get normal tickets
                    json = await doFetch(
                        `change/sandbox?team=${selectedTeam.teamNames}`,
                        'GET',
                        null,
                        null
                    );
                } else {
                    // get sandbox only
                    json = await doFetch(
                        `change/sandbox?ciNum=Test-Only-CI-9000`,
                        'GET',
                        null,
                        null
                    );
                }

                const reshapedData = json.result.map((res) => {
                    // turn outage planned and traffic shunt from booleans to string so it displays
                    res.outage_planned = res.outage_planned.toString();
                    res.traffic_shunt = res.traffic_shunt.toString();

                    return res;
                });
                setSandboxTickets(reshapedData);
                setSandboxOnlyTickets(!sandboxOnlyTickets);
            },
        },
    ];

    return (
        <Container>
            <MaterialTable
                title="Sandbox Tickets"
                columns={columns}
                options={options}
                icons={tableIcons}
                data={sandboxTickets}
                actions={actions}
            />
        </Container>
    );
});

export default SandboxTicketComponent;
