import React, { forwardRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Toolbar } from '@material-ui/core';
import './WorkforceComponent.css';

const WorkforceComponentTable = ({ data }) => {
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => (
            <DeleteOutline {...props} ref={ref} />
        )),
        DetailPanel: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
        )),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
            <ArrowUpward {...props} ref={ref} />
        )),
        ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
        )),
        ViewColumn: forwardRef((props, ref) => (
            <ViewColumn {...props} ref={ref} />
        )),
    };

    const [reshapedData, setReshapedData] = React.useState([]);

    React.useEffect(() => {
        const reshapedData = data.map((d) => {
            if (d.workload_host) {
                d.workload_host =
                    d.workload_host + ' (' + d.workload_host_id + ')';
            } else {
                d.workload_host = 'None';
            }
            return d;
        });
        setReshapedData(reshapedData);
    }, [data]);

    return (
        <MaterialTable
            title="Workload Catalog"
            icons={tableIcons}
            columns={[
                { title: 'CI ID', field: 'ci_id' },
                { title: 'Name', field: 'name' },
                { title: 'Description', field: 'description' },
                { title: 'Team', field: 'team' },
                {
                    title: 'Workload Host',
                    field: 'workload_host',
                    defaultGroupOrder: 0,
                },
                { title: 'App Type', field: 'app_type' },
                { title: 'Architecture Type', field: 'arch_type' },
                {
                    title: 'Status',
                    field: 'active',
                    render: (f) => <>{f.active ? 'Active' : 'Inactive'}</>,
                    lookup: { true: 'Active', false: 'Inactive' },
                },
            ]}
            data={reshapedData}
            options={{
                filtering: true,
                grouping: true,
                defaultExpanded: true,
            }}
            components={{
                Toolbar: (props) => (
                    <>
                        <Toolbar>
                            <div style={{ flexGrow: 1 }}>
                                <MTableToolbar {...props} />
                            </div>
                        </Toolbar>
                    </>
                ),
            }}
        />
    );
};

export default WorkforceComponentTable;
