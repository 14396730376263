import React, { useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Collapse,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const NestedListItem = React.memo(
    ({ children, text, indent = 0, ...props }) => {
        const theme = useTheme();
        const [isOpen, setOpen] = useState(true);
        const hasChildren = children && React.Children.count(children);

        const toggleOpen = () => {
            setOpen(!isOpen);
        };

        return (
            <>
                <ListItem
                    button
                    {...props}
                    style={
                        indent ? { paddingLeft: indent * theme.spacing(4) } : {}
                    }
                >
                    <ListItemText primary={text} />
                    {hasChildren && (
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="comments"
                                onClick={toggleOpen}
                            >
                                {isOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
                {hasChildren && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {React.Children.map(children, (Child) => (
                                <Child.type
                                    {...Child.props}
                                    indent={indent + 1}
                                />
                            ))}
                        </List>
                    </Collapse>
                )}
            </>
        );
    }
);

export default NestedListItem;
