import { useReducer, useCallback } from 'react';
import Config from '../config';
import memoize from 'memoizee';

const useFetch = (accessToken) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case 'INCREMENT': {
                return state + 1;
            }

            case 'DECREMENT': {
                return state - 1;
            }

            default: {
                return state;
            }
        }
    };
    const [state, dispatch] = useReducer(reducer, 0);
    const isLoading = state > 0;

    const internalFetch = useCallback(
        memoize(
            async (url, method, headers, body, accessToken) => {
                const response = await fetch(url, {
                    method: method,
                    mode: 'cors',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        ...headers,
                    },
                    body: body,
                });

                return await response.json();
            },
            { promise: true, maxAge: 900000 /* 15 min */ }
        ),
        []
    );

    const reset = useCallback(() => {
        internalFetch.clear();
    }, [internalFetch]);

    const doFetch = useCallback(
        async (endpoint, method, headers, body) => {
            dispatch({
                type: 'INCREMENT',
            });
            try {
                const result = await internalFetch(
                    `${Config.CHANGE_API.URL}/${endpoint}`,
                    method,
                    headers,
                    body,
                    accessToken
                );

                if (!accessToken) {
                    return;
                }

                // Verify result status
                if (!('status' in result)) {
                    window.alert('Error encountered');
                    console.log(result);
                } else if (result.status === 'error') {
                    window.alert(`Error: ${result.message}`);
                    console.log(result);
                }

                return result;
            } catch (error) {
                console.log(error);
                return error;
            } finally {
                dispatch({
                    type: 'DECREMENT',
                });
            }
        },
        [dispatch, accessToken, internalFetch]
    );

    return [doFetch, isLoading, reset];
};

export default useFetch;
