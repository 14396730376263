import React, { useState } from 'react';
import { MenuItem, Button, Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const AccessTokensForm = ({ teams, createAccessToken, selectedTeam }) => {
    const [team, setTeam] = useState('');
    const [tokenName, setTokenName] = useState('');

    const handleTeamChange = (event) => {
        setTeam(event.target.value);
    };

    const handleTokenNameChange = (event) => {
        setTokenName(event.target.value);
    };

    const handleClick = () => {
        if (team && tokenName) {
            createAccessToken(team, tokenName);
        }
    };

    const conditionalRender = () => {
        if (selectedTeam === 'My Teams') {
            return teams.map((team) => (
                <MenuItem key={team} value={team}>
                    {team}
                </MenuItem>
            ));
        } else {
            return (
                <MenuItem key={selectedTeam} value={selectedTeam}>
                    {selectedTeam}
                </MenuItem>
            );
        }
    };

    return (
        <Grid
            container
            spacing={2}
            style={{
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid item xs={7}>
                <TextField
                    id="token-name"
                    type="text"
                    label="Name"
                    margin="normal"
                    variant="outlined"
                    style={{ width: '100%' }}
                    value={tokenName}
                    onChange={handleTokenNameChange}
                />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    id="team"
                    select
                    label="Team"
                    margin="normal"
                    variant="outlined"
                    style={{ width: '100%' }}
                    value={team}
                    onChange={handleTeamChange}
                >
                    {conditionalRender()}
                </TextField>
            </Grid>

            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    aria-label="add"
                    onClick={handleClick}
                    startIcon={<AddIcon />}
                >
                    Create Token
                </Button>
            </Grid>
        </Grid>
    );
};

export default AccessTokensForm;
