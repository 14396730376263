import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';

import WorkforceComponentTable from './WorkforceComponentTable';

const WorkforceComponent = React.memo(({ doFetch, selectedTeam }) => {
    const [components, setComponents] = useState([]);

    useEffect(() => {
        const getComponents = async () => {
            const json = await doFetch(
                `components${selectedTeam.query}`,
                'GET',
                null,
                null
            );
            setComponents(json.result);
        };

        getComponents();
    }, [doFetch, selectedTeam]);

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <WorkforceComponentTable data={components} />
                </Grid>
            </Grid>
        </Container>
    );
});

export default WorkforceComponent;
