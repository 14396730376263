const config = {
    local: {
        CHANGE_API: {
            URL: 'http://127.0.0.1:8000',
        },
        COGNITO: {
            APP_WEB_DOMAIN:
                'auth.us-east-2.dev.changemanagement.coxautoinc.com',
            CLIENT_ID: '5j5c26mb45tss6rfmvjkr2neca',
            REDIRECT_URI: 'http://localhost:3000',
            LOGOUT_URI: 'http://localhost:3000/logout',
            USER_POOL_ID: 'us-east-2_No3bKvqC6',
        },
    },
    dev: {
        CHANGE_API: {
            URL: 'https://dev.changemanagement.coxautoinc.com',
        },
        COGNITO: {
            APP_WEB_DOMAIN:
                'auth.us-east-2.dev.changemanagement.coxautoinc.com',
            CLIENT_ID: '2jaebhtcib1tslvcs2cu378csv',
            REDIRECT_URI:
                'https://static.us-east-2.dev.changemanagement.coxautoinc.com',
            LOGOUT_URI:
                'https://static.us-east-2.dev.changemanagement.coxautoinc.com/logout',
            USER_POOL_ID: 'us-east-2_dWBYfmZAF',
        },
    },
    dev_us_east_2: {
        CHANGE_API: {
            URL: 'https://us-east-2.dev.changemanagement.coxautoinc.com',
        },
        COGNITO: {
            APP_WEB_DOMAIN:
                'auth.us-east-2.dev.changemanagement.coxautoinc.com',
            CLIENT_ID: '2jaebhtcib1tslvcs2cu378csv',
            REDIRECT_URI:
                'https://static.us-east-2.dev.changemanagement.coxautoinc.com',
            LOGOUT_URI:
                'https://static.us-east-2.dev.changemanagement.coxautoinc.com/logout',
            USER_POOL_ID: 'us-east-2_dWBYfmZAF',
        },
    },
    dev_us_west_2: {
        CHANGE_API: {
            URL: 'https://us-west-2.dev.changemanagement.coxautoinc.com',
        },
        COGNITO: {
            APP_WEB_DOMAIN:
                'auth.us-west-2.dev.changemanagement.coxautoinc.com',
            CLIENT_ID: '23aigffklv0e3eru6f10j1f1a8',
            REDIRECT_URI:
                'https://static.us-west-2.dev.changemanagement.coxautoinc.com',
            LOGOUT_URI:
                'https://static.us-west-2.dev.changemanagement.coxautoinc.com/logout',
            USER_POOL_ID: 'us-west-2_5CQ4UxlWz',
        },
    },
    prod: {
        CHANGE_API: {
            URL: 'https://changemanagement.coxautoinc.com',
        },
        COGNITO: {
            APP_WEB_DOMAIN: 'auth.us-east-2.changemanagement.coxautoinc.com',
            CLIENT_ID: '5qnn3l2bidhbqvcavhqs6s63kn',
            REDIRECT_URI:
                'https://static.us-east-2.changemanagement.coxautoinc.com',
            LOGOUT_URI:
                'https://static.us-east-2.changemanagement.coxautoinc.com/logout',
            USER_POOL_ID: 'us-east-2_ogTkC3Qb6',
        },
    },
    prod_us_east_2: {
        CHANGE_API: {
            URL: 'https://us-east-2.changemanagement.coxautoinc.com',
        },
        COGNITO: {
            APP_WEB_DOMAIN: 'auth.us-east-2.changemanagement.coxautoinc.com',
            CLIENT_ID: '5qnn3l2bidhbqvcavhqs6s63kn',
            REDIRECT_URI:
                'https://static.us-east-2.changemanagement.coxautoinc.com',
            LOGOUT_URI:
                'https://static.us-east-2.changemanagement.coxautoinc.com/logout',
            USER_POOL_ID: 'us-east-2_ogTkC3Qb6',
        },
    },
    prod_us_west_2: {
        CHANGE_API: {
            URL: 'https://us-west-2.changemanagement.coxautoinc.com',
        },
        COGNITO: {
            APP_WEB_DOMAIN: 'auth.us-west-2.changemanagement.coxautoinc.com',
            CLIENT_ID: '4v6058c017uc36grc06ee3mj56',
            REDIRECT_URI:
                'https://static.us-west-2.changemanagement.coxautoinc.com',
            LOGOUT_URI:
                'https://static.us-west-2.changemanagement.coxautoinc.com/logout',
            USER_POOL_ID: 'us-west-2_nIIY99yun',
        },
    },
    global: {
        docs: {
            swagger_url:
                'https://pages.ghe.coxautoinc.com/ETS-CloudAutomation/ChangeAPI/',
        },
        routes: {
            index: '/',
            logout: '/logout',
            workloadCatalog: '/workload-catalog',
            machineIdentities: '/machine-identities',
            accessTokens: '/access-tokens',
            sandboxTicket: '/sandbox-tickets',
        },
    },
};

const Config = {
    ...config.global,
    ...(config[process.env.REACT_APP_STAGE] || config.local),
};
export default Config;
